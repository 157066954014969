import { useCallback } from 'react';
import { Table, Button, Form, Modal, Space, message } from 'antd';
import SizeField from '../SizeField/SizeField';
import useRequest from 'hooks/useRequest';
import { ReactComponent as IncortaLogo } from 'images/incorta-blue-logo.svg';
import { Instance, SubCluster } from 'types/cluster';
import { customizeSelfManaged } from 'services/cluster';
type CustomizeModalProps = {
  instance: Instance;
  subclusters: SubCluster[];
  close: () => void;
  refetchClusters: Function;
};

function InstanceUsers({
  instance,
  subclusters,
  close,
  refetchClusters,
}: CustomizeModalProps) {
  const [form] = Form.useForm();

  let { makeRequest } = useRequest(
    useCallback(
      async formattedValues => {
        const response = await customizeSelfManaged(instance?.id, {
          subclusters: formattedValues,
        });
        if (response?.status < 400) {
          message.success('Customization successful!');
        } else {
          message.error('Customization failed. Please try again.');
        }
        await refetchClusters();
      },
      [instance?.id, refetchClusters],
    ),
    {
      handleError: true,
    },
  );

  const initialValues = subclusters.reduce((acc, record) => {
    acc[`${record.name}`] = record.sizeID;
    return acc;
  }, {} as Record<string, number>);

  const handleFinish = async (values: Record<string, string>) => {
    const formattedValues = Object.keys(values).map(key => ({
      name: key.replace('size_', ''),
      size: values[key],
    }));
    await makeRequest(formattedValues);
    close();
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 200,
      render: (_, record) => (
        <>
          {record.type === 'sqlx' ? (
            <span
              style={{
                fontSize: 'inherit',
                display: 'inline-flex',
                alignItems: 'center',
              }}
            >
              <i style={{ marginRight: '4px', fontSize: '0.7em' }}>SQLX</i> Sqlx
            </span>
          ) : (
            <IncortaLogo
              style={{
                width: '1.5em',
                height: '1.5em',
                position: 'relative',
                top: '4px',
              }}
            />
          )}{' '}
          {record.configs && JSON.parse(record.configs).fallbackAnalytics
            ? 'Failover Analytics'
            : record.type !== 'sqlx'
            ? 'Analytics'
            : ''}
        </>
      ),
    },
    {
      title: 'Size',
      dataIndex: 'sizeDisplayName',
      key: 'sizeDisplayName',
      width: 200,
      render: (_, record) => (
        <Form.Item
          name={`${record.name}`}
          rules={[{ required: true, message: 'Please select a size' }]}
        >
          <SizeField
            label=""
            name={`${record.name}`}
            instanceID={instance.id}
            pod="analytics"
          />
        </Form.Item>
      ),
    },
  ];

  return (
    <Modal
      title="Self Managed Customizations"
      visible={true}
      footer={null}
      width={'50%'}
      onCancel={close}
    >
      <Form form={form} initialValues={initialValues} onFinish={handleFinish}>
        <Table dataSource={subclusters} columns={columns} pagination={false} />

        <Space style={{}}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default InstanceUsers;
