import './ProgressLogs.less';

import React, { useState, useCallback, useEffect } from 'react';
import { Input, Select, Form, Button, Space, Spin } from 'antd';
import useRequest from 'hooks/useRequest';
import { showErrorMessage } from 'utils/errors';

import { getProgressLogs } from 'services/progressLogs';

const { Option } = Select;
const { TextArea } = Input;

function generateReplicaServices(serviceName: string, nodeCount: number) {
  return Array.from({ length: nodeCount }, (_, index) => {
    return {
      name: `${serviceName}-${index}`,
      index,
    };
  });
}

function ProgressLogs({ instance, close }) {
  const [form] = Form.useForm();
  let [instanceName] = useState(instance?.name);
  let [action, setAction] = useState('creation');
  let [service, setService] = useState('analytics-0');
  let [loading, setLoading] = useState(false);

  let { makeRequest } = useRequest(
    useCallback(async () => {
      const progressLogsObject = await getProgressLogs(instanceName, action);
      if (instance.status === 'disconnected') {
        showErrorMessage(
          'The cluster must be connected to get its progress logs',
        );
        close();
      } else if (progressLogsObject.status >= 400) {
        showErrorMessage(progressLogsObject.data.message);
        close();
      } else if (progressLogsObject.data.progress.initializing) {
        form.setFieldsValue({
          progress: progressLogsObject.data.progress.initializing,
        });
      } else if (service === 'cmc') {
        form.setFieldsValue({
          progress: progressLogsObject.data.progress.cmc,
        });
      } else {
        form.setFieldsValue({
          progress: progressLogsObject.data.progress[service],
        });
      }
      setLoading(false);

      // eslint-disable-next-line
    }, [instanceName, action, service]),
    {
      handleError: true,
    },
  );
  function handleFetch() {
    makeRequest();
  }
  useEffect(() => {
    setLoading(true);
    handleFetch();

    // eslint-disable-next-line
  }, []);

  return (
    <Form
      form={form}
      initialValues={{
        action: 'creation',
        service: 'analytics-0',
      }}
    >
      <div>
        <Form.Item name="action">
          <Select onChange={setAction}>
            <Option value="creation">Creation</Option>
            <Option value="wakeup">Wakeup</Option>
            <Option value="upgrade">Upgrade</Option>
            <Option value="pod">Pod Logs</Option>
          </Select>
        </Form.Item>
        <Form.Item name="service">
          <Select onChange={setService}>
            {generateReplicaServices('analytics', instance.analyticsNodes).map(
              s => {
                return (
                  <Option key={s.name} value={s.name}>
                    Analytics-{s.index}
                  </Option>
                );
              },
            )}

            {generateReplicaServices('loader', instance.loaderNodes).map(s => {
              return (
                <Option key={s.name} value={s.name}>
                  Loader-{s.index}
                </Option>
              );
            })}

            <Option value="cmc">CMC</Option>
            <Option value="copilot">Copilot</Option>
          </Select>
        </Form.Item>
        <Spin
          tip="Fetching Progress Logs"
          spinning={loading}
          className="ProgressLogs__textarea"
        >
          <Form.Item name="progress">
            <TextArea disabled className="ProgressLogs__textarea" />
          </Form.Item>
        </Spin>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={handleFetch}
          >
            Fetch Logs
          </Button>
        </Space>
      </div>
    </Form>
  );
}

export default ProgressLogs;
